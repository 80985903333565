.nav {
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 5px;
  padding: 10px 5px;
  color: white !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease;
  font-family: 'Inter', sans-serif;
  animation: 0.3s all ease-out;
}
.nav:hover {
  color: #FFFFFF;
  background-color: transparent;
}

.activeNav {
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 5px;
  padding: 10px 5px;
  color: #00B6B0 !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease;
  font-family: 'Inter', sans-serif;
  animation: 0.3s all ease-out;
}

.activeNav:after {
  content: "";
  position: absolute;
  bottom: 10px;
  width: calc(100% - 10px);
  height: 2px;
  background: #00B6B0;
}

.envLabel {
  color: #FFFFFF;
  margin-left: 8px;
  margin-bottom: 0;
}