@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900);
html, body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.styles_nav__NOzFE {
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 5px;
  padding: 10px 5px;
  color: white !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease;
  font-family: 'Inter', sans-serif;
  animation: 0.3s :local(all) ease-out;
}
.styles_nav__NOzFE:hover {
  color: #FFFFFF;
  background-color: transparent;
}

.styles_activeNav__2O0ad {
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 5px;
  padding: 10px 5px;
  color: #00B6B0 !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease;
  font-family: 'Inter', sans-serif;
  animation: 0.3s :local(all) ease-out;
}

.styles_activeNav__2O0ad:after {
  content: "";
  position: absolute;
  bottom: 10px;
  width: calc(100% - 10px);
  height: 2px;
  background: #00B6B0;
}

.styles_envLabel__F-32k {
  color: #FFFFFF;
  margin-left: 8px;
  margin-bottom: 0;
}
